import React from 'react';
import SEO from '../../components/seo';
import Landing from '../../components/landing';

import {
  DocumentDuplicateIcon,
  PresentationChartBarIcon,
  CalendarIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';

const features = [
  {
    name: 'Bulk & APIs first',
    description:
      'Generate invoices from CSV or with our APIs and send them automatically on the issue date.',
    icon: DocumentDuplicateIcon,
  },
  {
    name: 'Auto-reconciled',
    description:
      'We reconcile your customer payments once the invoice has been paid and sync with your accounting software.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Automate collections',
    description:
      'With our powerful dunning workflows you can send automated reminders to your customers',
    icon: CalendarIcon,
  },
  {
    name: 'Reporting',
    description:
      'Get an overview on taxes, best customers, and which invoices are overdue.',
    icon: PresentationChartBarIcon,
  },
];
function AccountsReceivablePlatform({ location }) {
  return (
    <>
      <SEO title="Automate your Accounts Receivable" path={location.pathname} />

      <Landing
        header={
          <h1 className="text-4xl tracking-tight font-bold text-gray-200 sm:text-4xl md:text-6xl">
            <span className="block">Simplify your B2B sales </span>
            <span className="inline bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 bg-clip-text font-display tracking-tight text-transparent">
              with CRM integrations and automated dunning
            </span>
          </h1>
        }
        headerCTA={
          <p className="text-base text-gray-200 pt-2 sm:text-xl lg:text-lg xl:text-xl">
            Create and send compliant invoices in a few clicks. Once the payment
            is received we automatically reconcile it. And if the payment is
            overdue we follow up with your customer.
          </p>
        }
        features={features}
        featuresCTA="Automate your accounts receivable."
      />
    </>
  );
}

export default AccountsReceivablePlatform;
