import React, { useEffect } from 'react';
import { Popover } from '@headlessui/react';
import { Link } from 'gatsby';
import FeatureBlock from './featureBlocks';
import Logo from '../images/logo.svg';
import { siteMap } from '../utils/sitemap';

function Landing({ header, headerCTA, features, featuresCTA }) {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);

  return (
    <div>
      <Popover className="relative">
        <div className="flex justify-between items-center px-4 py-6 sm:px-24 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={siteMap.HOME.link}>
              <Logo className="h-4 w-auto sm:h-5" />
            </Link>
          </div>
        </div>
      </Popover>
      <div className="px-10">
        <div className="lg:grid lg:grid-cols-2 pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-full lg:px-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div className="lg:py-24">
                {header}
                {headerCTA}
              </div>
            </div>
          </div>
          <div className="mx-auto pt-8 lg:px-8">
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/tresorbase/30min?hide_event_type_details=1"
              style={{ minWidth: 320, height: 640 }}
            />
          </div>
        </div>
      </div>

      <FeatureBlock features={features} title={featuresCTA} />
    </div>
  );
}

export default Landing;
